var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { UserRole } from '../../../types/authentication';
export var userInitialState = {
    memberId: '',
    name: '',
    token: '',
    registered: false,
    loggedIn: false,
    cookiesSaveAgree: false,
    registrationCode: '',
    role: UserRole.USER,
    referred: false,
};
export var userReducer = function (state, action) {
    var _a;
    switch (action.type) {
        case 'COOKIES_SAVE_AGREE':
            return __assign(__assign({}, state), { cookiesSaveAgree: true });
        case 'REGISTRATION_SUCCESS':
            return __assign(__assign({}, state), { name: action.payload });
        case 'LOGIN':
            return __assign(__assign({}, state), { memberId: action.payload.memberId, loggedIn: true, registered: false, registrationCode: action.payload.registrationCode, name: action.payload.name, token: action.payload.token, role: action.payload.role, referred: (_a = action.payload.referred) !== null && _a !== void 0 ? _a : false });
        case 'LOGOUT':
            return __assign(__assign({}, userInitialState), { registrationCode: state.registrationCode, cookiesSaveAgree: state.cookiesSaveAgree });
    }
    return state;
};
export * from './actions';
