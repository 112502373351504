var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseGateway } from './BaseGateway';
var MemberGateway = /** @class */ (function (_super) {
    __extends(MemberGateway, _super);
    function MemberGateway() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /** @deprecated */
    MemberGateway.prototype.getOrganizationBy = function (registrationCode) {
        return this.connection.get("/organisations/" + registrationCode);
    };
    /** @deprecated */
    MemberGateway.prototype.createMember = function (data) {
        return this.connection.post("/organisations/" + data.company + "/members", data.member);
    };
    /** @deprecated */
    MemberGateway.prototype.getPackages = function () {
        return this.connection.get('/packages');
    };
    /** @deprecated */
    MemberGateway.prototype.getSubscriptions = function () {
        return this.connection.get('/subscriptions');
    };
    MemberGateway.prototype.subscribeToPackage = function (packageId) {
        return this.connection.post('/subscriptions', {
            packageId: packageId,
        });
    };
    /** @deprecated */
    MemberGateway.prototype.getParq = function () {
        return this.connection.get('/parqs');
    };
    /** @deprecated */
    MemberGateway.prototype.sendParq = function (data) {
        return this.connection.post('/parqs', data);
    };
    /** @deprecated */
    MemberGateway.prototype.getSettings = function () {
        return this.connection.get('/members/settings');
    };
    /** @deprecated */
    MemberGateway.prototype.updateSettings = function (key, values) {
        return this.connection.patch('/members/settings', {
            name: key,
            values: values,
        });
    };
    return MemberGateway;
}(BaseGateway));
export { MemberGateway };
