import TagManager from 'react-gtm-module';
if (process.env.GTM_ID) {
    TagManager.initialize({
        gtmId: process.env.GTM_ID,
    });
}
export var mapboxToken = process.env.MAPBOX_TOKEN;
export var smoothScrollTo = function (selector) {
    var _a;
    return (_a = document.querySelector(selector)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
        behavior: 'smooth',
    });
};
export var handleScrollToTarget = function (e) {
    var selector = e.target.getAttribute('href');
    if (selector && selector.indexOf('#') === 0) {
        e.preventDefault();
        e.stopPropagation();
        smoothScrollTo(selector);
    }
};
export var getLocationHash = function () { return document.location.hash.replace(/#/g, ''); };
export var resolveGymImageUrl = function (gymId, imageName, size) {
    return process.env.STATIC_BASE_URL + "/gym_img/" + (size || '465x335') + "/" + gymId + "/" + imageName;
};
export var buildQuery = function (params) {
    return Object.entries(params)
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        if (Array.isArray(value)) {
            return value.map(function (element) { return key + "[]=" + element; }).join('&');
        }
        return key + "=" + value;
    })
        .join('&');
};
export var toFixedNumber = function (value, fraction) {
    if (fraction === void 0) { fraction = 2; }
    return value % 1 === 0 ? value.toString(10) : value.toFixed(fraction);
};
export * from './Chargebee';
export * from './Validation';
