var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { lazy, Suspense, useContext, useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from './Routes';
import { AuthContext } from './services/authentication';
var LoginFinder = lazy(function () { return import('@pages/LoginFinder'); });
var GymDetails = lazy(function () { return import('@pages/GymDetails'); });
var Gyms = lazy(function () { return import('@pages/Gyms'); });
var LegalCookies = lazy(function () { return import('@pages/LegalCookies'); });
var LegalTerms = lazy(function () { return import('@pages/LegalTerms'); });
var LegalPrivacy = lazy(function () { return import('@pages/LegalPrivacy'); });
var AccountPasses = lazy(function () { return import('@/pages/AccountPasses'); });
var AccountSettings = lazy(function () { return import('@pages/AccountSettings'); });
var NotFound = lazy(function () { return import('@pages/NotFound'); });
var OrganisationLanding = lazy(function () { return import('@pages/OrganisationLanding'); });
var Parq = lazy(function () { return import('@pages/Parq'); });
var Register = lazy(function () { return import('@pages/Register'); });
var CompleteRegistration = lazy(function () { return import('./pages/Register/CompleteRegistration'); });
var RegisterComplete = lazy(function () { return import('@pages/RegisterComplete'); });
var RenewPassword = lazy(function () { return import('@pages/RenewPassword'); });
var ResetPassword = lazy(function () { return import('@pages/ResetPassword'); });
var Admin = lazy(function () { return import('@pages/Admin'); });
var Home = lazy(function () { return import('@pages/Home'); });
var Onboard = lazy(function () { return import('./pages/Onboard/Onboard'); });
var Passbuilder = lazy(function () { return import('./pages/Passbuilder/Passbuilder'); });
var PassbuilderRecommended = lazy(function () { return import('./pages/Passbuilder/Recommended'); });
var routes = [
    { path: [Routes.home], component: Home },
    { path: [Routes.legalCookies, Routes.companyLegalCookies], component: LegalCookies },
    { path: [Routes.legalTerms, Routes.companyLegalTerms], component: LegalTerms },
    { path: [Routes.legalPrivacy, Routes.companyLegalPrivacy], component: LegalPrivacy },
    { path: [Routes.renewPassword, Routes.companyRenewPassword], component: RenewPassword },
    { path: [Routes.resetPassword, Routes.companyResetPassword], component: ResetPassword },
    { path: [Routes.companyRegister], component: Register },
    { path: [Routes.companyRegisterProfile], component: CompleteRegistration, restricted: true },
    { path: [Routes.companyRegisterComplete], component: RegisterComplete },
    { path: [Routes.loginFinder], component: LoginFinder },
    {
        path: [Routes.companyJoin, Routes.companyHome, Routes.companyLogin],
        component: OrganisationLanding,
        strict: false,
    },
    {
        path: Routes.companyGyms,
        component: Gyms,
        strict: false,
    },
    { path: Routes.companyGymDetails, component: GymDetails },
    { path: Routes.companyAccountPasses, component: AccountPasses, restricted: true },
    { path: Routes.companyAccountSettings, component: AccountSettings, restricted: true },
    { path: Routes.companyPurchaseParq, component: Parq, restricted: true },
    { path: Routes.adminMembers, component: Admin, restricted: true },
    { path: Routes.companyOnboard, component: Onboard },
    { path: Routes.companyPassbuilder, component: Passbuilder },
    { path: Routes.companyPassbuilderRecommendation, component: PassbuilderRecommended },
];
var redirects = [
    { from: Routes.companyAccountPassesAlias, to: Routes.companyAccountPasses },
    { from: Routes.companyJoinAlias, to: Routes.companyJoin },
    { from: Routes.companyLogin, to: Routes.companyHome, restricted: true },
    { from: Routes.companyRegister, to: Routes.companyHome, restricted: true },
];
export var AppRouter = function () {
    var _a = useContext(AuthContext).state, loggedIn = _a.loggedIn, registrationCode = _a.registrationCode;
    var routingList = useMemo(function () {
        return routes
            .filter(function (route) { return !route.restricted || route.restricted === loggedIn; })
            .map(function (route, index) { return React.createElement(Route, __assign({ key: "app_route_" + index, exact: true, strict: true }, route)); });
    }, [loggedIn]);
    var redirectList = useMemo(function () {
        return redirects
            .filter(function (redirect) { return !redirect.restricted || redirect.restricted === loggedIn; })
            .map(function (redirect, index) { return React.createElement(Redirect, __assign({ key: "app_redirect_" + index, exact: true, strict: true }, redirect)); });
    }, [loggedIn, registrationCode]);
    return (React.createElement(Router, null,
        React.createElement(Switch, null,
            redirectList,
            React.createElement(Route, { path: [Routes.companyHome, '*'], strict: true },
                React.createElement(Suspense, { fallback: React.createElement("div", { className: "preloader preloader--lg" }) },
                    React.createElement(Switch, null,
                        routingList,
                        React.createElement(Route, { path: "*", component: NotFound })))))));
};
