export var members = function (connection) { return ({
    getOrganisationUrlFromEmail: function (email) {
        return connection.get("/members/organisation-url/" + email);
    },
    getRegistrationState: function (memberId) {
        return connection.get("/members/" + memberId + "/registrationstate");
    },
    create: function (request) {
        return connection.post("/organisations/" + request.company + "/members", request.member);
    },
    update: function (memberId, member) {
        return connection.patch("/members/" + memberId, {
            firstName: member.first_name,
            lastName: member.last_name,
            marketingOptIn: member.agreed_terms,
        });
    },
    parq: {
        getAll: function () { return connection.get('/parqs'); },
        save: function (request) { return connection.post('/parqs', request); },
    },
    settings: {
        getAll: function () {
            return connection.get('/members/settings');
        },
        update: function (key, values) {
            return connection.patch('/members/settings', {
                name: key,
                values: Array.isArray(values) ? values.map(function (v) { return ({ name: v.name, value: v.value }); }) : values,
            });
        },
    },
    getReferral: function () {
        return connection.get('/members/referral');
    },
    integrations: {
        activate: function (integration) { return connection.post("/integrations/" + integration.name); },
    },
    subscriptions: {
        get: function () { return connection.get('/subscriptions'); },
    },
    packages: {
        getAll: function () { return connection.get('/packages'); },
    },
    saveCharacteristics: function (characteristics) {
        return connection.post('/members/characteristics', {
            fitness_goals: [characteristics.goal],
            gym_locations: characteristics.locations,
            budget: characteristics.budget,
            recommended_pass: characteristics.recommendedPass.toLowerCase(),
            digital_apps: characteristics.digitalApps,
        });
    },
}); };
