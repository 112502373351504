var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var authentication = function (connection) { return ({
    login: function (_a) {
        var email = _a.email, password = _a.password;
        return connection.post('/tokens', { email: email, password: password });
    },
    verifyEmail: function (email, returnUrl) {
        return connection.post('/verifications', __assign({}, (email && { identifier: email, method: 'email' })), {
            params: {
                returnUrl: returnUrl,
            },
        });
    },
    passwords: {
        reset: function (email) {
            return connection.post('/passwords', {
                email: email,
            });
        },
        update: function (_a) {
            var memberId = _a.memberId, expires = _a.expires, signature = _a.signature, password = _a.password, marketingOptIn = _a.marketingOptIn;
            return connection.put("/passwords/" + memberId + "?signature=" + signature + "&expires=" + expires, {
                password: password,
                marketing_opt_in: marketingOptIn,
            }, { withCredentials: true });
        },
    },
}); };
