export var gyms = function (connection) { return ({
    find: function (id, options) {
        return connection.get("/gyms/" + id + ((options === null || options === void 0 ? void 0 : options.includeReviews) ? '?include=reviews' : ''));
    },
    findAll: function (options) {
        if (options === void 0) { options = {}; }
        var _a = options.page, page = _a === void 0 ? 1 : _a, packageId = options.packageId, locationDistance = options.locationDistance, locationCoords = options.locationCoords, query = options.query, resources = options.resources;
        var queryParams = Object.entries({
            'page[number]': page,
            'filter[package_id]': packageId,
            'filter[location_coords]': locationCoords,
            'filter[location_distance]': locationDistance,
            'filter[query]': query,
            'filter[resources]': resources,
        })
            .filter(function (_a) {
            var value = _a[1];
            return value !== undefined;
        })
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            if (Array.isArray(value)) {
                return value.map(function (element) { return key + "[]=" + element; }).join('&');
            }
            return key + "=" + value;
        })
            .join('&');
        return connection.get("/gyms?" + queryParams);
    },
    search: function (options) {
        var queryParams = options
            .map(function (_a) {
            var _b;
            var locationName = _a.locationName, _c = _a.query, locationCoords = _c.locationCoords, packageId = _c.packageId, page = _c.page;
            return Object.entries((_b = {},
                _b["page[" + locationName + "][number]"] = page || 1,
                _b["filter[" + locationName + "][package_id]"] = packageId,
                _b["filter[" + locationName + "][location_coords]"] = locationCoords,
                _b))
                .filter(function (_a) {
                var value = _a[1];
                return value !== undefined;
            })
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                if (Array.isArray(value)) {
                    return value.map(function (element) { return key + "[]=" + element; }).join('&');
                }
                return key + "=" + value;
            })
                .join('&');
        })
            .join('&');
        return connection.get("/gyms/search?" + queryParams);
    },
}); };
