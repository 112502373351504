/**
 * Polyfill for the closest function of element.
 */
export var Polyfills = function () {
    if (!Element.prototype.closest) {
        var findMatches_1 = function (selector, element) {
            if (element.matches(selector)) {
                return element;
            }
            if (element.parentElement !== null && element.nodeType === 1) {
                return findMatches_1(selector, element.parentElement);
            }
            return null;
        };
        Element.prototype.closest = function (selector) {
            return findMatches_1(selector, this);
        };
    }
};
