var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import { decodeJWT } from '../services/authentication';
import { AuthGateway, GymsGateway, MemberGateway } from './gateways';
import { SubscriptionService } from './services';
/**
 * Do NOT use these gateways to add more api endpoints.
 * Add new api endpoints at /src/services/api
 *
 * These endpoints will exist only as long as they have been properly replaced
 */
export var apiConnection = axios.create({
    baseURL: process.env.API_BASE_URL,
});
// GATEWAYS
export var authGateway = new AuthGateway(apiConnection);
export var gymsGateway = new GymsGateway(apiConnection);
export var memberGateway = new MemberGateway(apiConnection);
var gateways = {
    authGateway: authGateway,
    gymsGateway: gymsGateway,
    memberGateway: memberGateway,
};
// SERVICES
export var subscriptionService = new SubscriptionService(gateways);
// UTILS
export var applyAuthorizationToken = function (authorizationToken) {
    if (authorizationToken) {
        var token = decodeJWT(authorizationToken);
        apiConnection.defaults.headers = __assign(__assign({}, apiConnection.defaults.headers), { Authorization: "Bearer " + authorizationToken, 'X-Organisation': token.registrationCode });
    }
    else {
        delete apiConnection.defaults.headers.Authorization;
        delete apiConnection.defaults.headers['X-Organisation'];
    }
};
export var applyOrganizationHeader = function (registrationCode) {
    if (registrationCode) {
        apiConnection.defaults.headers = __assign(__assign({}, apiConnection.defaults.headers), { 'X-Organisation': registrationCode });
    }
    else {
        delete apiConnection.defaults.headers['X-Organisation'];
    }
};
