export var RouteParam;
(function (RouteParam) {
    RouteParam["COMPANY"] = "company";
    RouteParam["GYM_ID"] = "gymId";
    RouteParam["RESET_URL"] = "reset";
})(RouteParam || (RouteParam = {}));
export var UrlParams;
(function (UrlParams) {
    UrlParams["DISTANCE"] = "distance";
    UrlParams["PASS"] = "pass";
    UrlParams["PAGE"] = "page";
    UrlParams["SEARCH"] = "search";
    UrlParams["RESOURCES"] = "resources";
})(UrlParams || (UrlParams = {}));
export var Routes = {
    home: '/',
    login: '/login',
    register: '/register',
    legalTerms: '/legal/terms',
    legalPrivacy: '/legal/privacy',
    legalCookies: '/legal/cookies',
    resetPassword: "/resetpassword",
    renewPassword: "/reset",
    loginFinder: "/login/finder",
    // Admin routes
    adminMembers: "/:" + RouteParam.COMPANY + "/admin/members",
    // COMPANY ROUTES
    companyHome: "/:" + RouteParam.COMPANY + "/",
    companyAccountPasses: "/:" + RouteParam.COMPANY + "/account/passes",
    companyAccountSettings: "/:" + RouteParam.COMPANY + "/account/settings",
    companyLegalTerms: "/:" + RouteParam.COMPANY + "/legal/terms",
    companyLegalPrivacy: "/:" + RouteParam.COMPANY + "/legal/privacy",
    companyLegalCookies: "/:" + RouteParam.COMPANY + "/legal/cookies",
    companyLogin: "/:" + RouteParam.COMPANY + "/login",
    companyJoin: "/:" + RouteParam.COMPANY + "/join",
    companyRegister: "/:" + RouteParam.COMPANY + "/register",
    companyRegisterComplete: "/:" + RouteParam.COMPANY + "/register/complete",
    companyRegisterProfile: "/:" + RouteParam.COMPANY + "/register/profile",
    companyGyms: "/:" + RouteParam.COMPANY + "/gyms",
    companyGymDetails: "/:" + RouteParam.COMPANY + "/gyms/:" + RouteParam.GYM_ID,
    companyOnboard: "/:" + RouteParam.COMPANY + "/onboard",
    companyPurchaseParq: "/:" + RouteParam.COMPANY + "/purchase/parq",
    companyResetPassword: "/:" + RouteParam.COMPANY + "/resetpassword",
    companyRenewPassword: "/:" + RouteParam.COMPANY + "/reset",
    companyPassbuilder: "/:" + RouteParam.COMPANY + "/personalised-pass/:step(goal|locations|location-codes|digital-fitness|budget)",
    companyPassbuilderRecommendation: "/:" + RouteParam.COMPANY + "/personalised-pass/recommended",
    // ALIASES
    companyJoinAlias: "/join/:" + RouteParam.COMPANY,
    companyAccountPassesAlias: "/:" + RouteParam.COMPANY + "/account/",
};
/**
 * Get route path with/without filling routeParam and.
 *
 * @param {String} route - string key of {@link Routes}.
 * @param {RouteOptions} routeParam - route parameters for given route enumerated in {@link RouteParam}.
 * @param {UrlOptions} queryParams - query parameters for given route enumerated in {@link UrlParams}.
 */
export var getRoute = function (route, routeParam, queryParams) {
    var uri = routeParam
        ? Object.keys(routeParam).reduce(function (acc, paramName) {
            var value = routeParam[paramName];
            return acc.replace(":" + paramName, value);
        }, Routes[route])
        : Routes[route];
    if (queryParams) {
        var query = Object.keys(queryParams)
            .map(function (key) { return key + "=" + queryParams[key]; })
            .join('&');
        return uri + "?" + query;
    }
    return uri;
};
