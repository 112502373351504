import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
export var CookieContext = createContext({});
export var CookieContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), agreedToCookies = _b[0], setAgreedToCookies = _b[1];
    useEffect(function () {
        var cookies = Cookies.get();
        setAgreedToCookies(JSON.parse(cookies['agreedToCookies'] || 'false'));
    }, []);
    var dismissCookieBanner = function () {
        Cookies.set('agreedToCookies', JSON.stringify(true));
        setAgreedToCookies(true);
    };
    return React.createElement(CookieContext.Provider, { value: { agreedToCookies: agreedToCookies, dismissCookieBanner: dismissCookieBanner } }, children);
};
