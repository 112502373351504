var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import Cookies from 'js-cookie';
import { members } from './members';
import { admin } from './admin';
import { organisations } from './organisations';
import { gyms } from './gyms';
import { authentication } from './authentication';
var defaultConfig = {
    baseURL: process.env.API_BASE_URL,
};
var createApi = function (config) {
    var connection = axios.create(config);
    connection.interceptors.request.use(function (config) {
        var cookies = Cookies.get();
        var state = cookies['state'];
        if (!state) {
            return config;
        }
        var decodedCookies = atob(state || '');
        var userInformation = JSON.parse(decodedCookies);
        if (userInformation.token) {
            config.headers.Authorization = "Bearer " + userInformation.token;
        }
        if (userInformation.registrationCode) {
            config.headers['X-Organisation'] = "" + userInformation.registrationCode;
        }
        return config;
    });
    return {
        connection: connection,
        applyOrganisationHeader: function (organisation) {
            var configWithOrganisationHeader = __assign(__assign({}, config), { headers: __assign(__assign({}, config.headers), { 'X-Organisation': organisation }) });
            return createApi(configWithOrganisationHeader);
        },
        admin: admin(connection),
        authentication: authentication(connection),
        members: members(connection),
        organisations: organisations(connection),
        gyms: gyms(connection),
    };
};
export var api = createApi(defaultConfig);
