var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { getRoute } from '@Routes';
import { Chargebee, CHARGEBEE_URL } from '@utils';
import { BaseService } from './BaseService';
import { trackPurchase } from '../../services/analytics/trackPurchase';
var SubscriptionService = /** @class */ (function (_super) {
    __extends(SubscriptionService, _super);
    function SubscriptionService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SubscriptionService.prototype.subscribe = function (data) {
        var _this = this;
        var packageId = data.packageId, successCallback = data.successCallback, loadingCallback = data.loadingCallback;
        Chargebee.getInstance().openCheckout({
            hostedPage: function () {
                return new Promise(function (resolve) {
                    _this.gateways.memberGateway
                        .subscribeToPackage(packageId)
                        .then(function (_a) {
                        var _b = _a.data, isPaymentRequired = _b.isPaymentRequired, payment = _b.payment;
                        loadingCallback && loadingCallback();
                        if (isPaymentRequired && payment) {
                            resolve({
                                id: payment.chargebee.checkoutId,
                                type: 'checkout_new',
                                url: CHARGEBEE_URL + "/pages/v3/" + payment.chargebee.checkoutId + "/",
                                state: 'created',
                                embed: true,
                                created_at: new Date().getTime(),
                                expires_at: new Date().getTime() + 60000,
                            });
                        }
                        else if (isPaymentRequired && !payment) {
                            console.error('Payment method is required but server response with payment empty field.');
                        }
                        else {
                            document.location.href = getRoute('home');
                        }
                    })
                        .catch(function (errors) {
                        console.error(errors);
                        loadingCallback && loadingCallback();
                    });
                });
            },
            loaded: function () {
                loadingCallback && loadingCallback();
            },
            error: function ( /* error */) {
                loadingCallback && loadingCallback();
            },
            success: function () {
                trackPurchase(parseInt(packageId, 10));
                successCallback();
            },
        });
    };
    return SubscriptionService;
}(BaseService));
export { SubscriptionService };
