import * as Yup from 'yup';
import { i18n } from '../i18n';
export var yupEmail = Yup.string()
    .email(i18n.t('errors:invalidEmailFormat'))
    .required(i18n.t('errors:isRequired', { label: 'Email' }));
export var yupPassword = Yup.string()
    .min(6, i18n.t('errors:passwordMinLength'))
    .required(i18n.t('errors:isRequired', { label: 'Password' }));
export var yupAgree = Yup.boolean()
    .required()
    .oneOf([true], i18n.t('errors:isRequired', { label: 'This value' }));
