var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { buildQuery } from '@utils';
import { BaseGateway } from './BaseGateway';
/** @deprecated */
var GymsGateway = /** @class */ (function (_super) {
    __extends(GymsGateway, _super);
    function GymsGateway() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /** @deprecated */
    GymsGateway.prototype.getGymsList = function (data) {
        var filters = Object.keys(data.filters).reduce(function (acc, key) {
            var _a;
            if (!data.filters[key]) {
                return acc;
            }
            var keyParam = ['package_id', 'location_coords', 'location_distance'].includes(key)
                ? "filter[" + key + "]"
                : "filter[" + key.split('_').join('][') + "]";
            var value = data.filters[key];
            return __assign(__assign({}, acc), (_a = {}, _a[keyParam] = value, _a));
        }, {});
        var params = __assign({ 'page[number]': data.page }, filters);
        return this.connection.get("/gyms?" + buildQuery(params));
    };
    /** @deprecated */
    GymsGateway.prototype.getGym = function (gymId) {
        return this.connection.get("/gyms/" + gymId + "?include=reviews");
    };
    return GymsGateway;
}(BaseGateway));
export { GymsGateway };
