var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useEffect, useReducer, useState } from 'react';
import Cookies from 'js-cookie';
import { applyAuthorizationToken } from '@domain';
import { userInitialState, userReducer } from '@store';
import { api } from '../../services/api';
import { decodeJWT } from './utils';
export var AuthContext = createContext({});
export var AuthContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), isLoaded = _b[0], setIsLoaded = _b[1];
    var _c = useState(false), justVerified = _c[0], setJustVerified = _c[1];
    var _d = useReducer(userReducer, userInitialState, function () {
        try {
            var cookie = Cookies.get();
            var tokenCookie = cookie['token'] || '';
            Cookies.remove('token');
            try {
                var tokenCookieData = decodeJWT(tokenCookie);
                if (!!tokenCookieData) {
                    setJustVerified(true);
                    applyAuthorizationToken(tokenCookie);
                    return __assign(__assign({}, userInitialState), { memberId: tokenCookieData.memberId, loggedIn: true, registrationCode: tokenCookieData.registrationCode, name: tokenCookieData.name, token: tokenCookie, role: tokenCookieData.role, referred: tokenCookieData.referred });
                }
            }
            catch (errors) {
                // ignore and move on
            }
            var cookies = Cookies.get();
            var stateFromCookie = atob(cookies['state'] || '');
            if (stateFromCookie) {
                var newState = JSON.parse(stateFromCookie);
                applyAuthorizationToken(newState.token);
                setJustVerified(false);
                return newState;
            }
            setJustVerified(false);
            return userInitialState;
        }
        catch (e) {
            return userInitialState;
        }
        finally {
            setIsLoaded(true);
        }
    }), state = _d[0], dispatch = _d[1];
    var login = function (_a) {
        var email = _a.email, password = _a.password;
        return api.authentication.login({ email: email, password: password }).then(function (_a) {
            var token = _a.data.token;
            var data = decodeJWT(token);
            dispatch({
                type: 'LOGIN',
                payload: {
                    memberId: data.memberId.toString(),
                    registrationCode: data.registrationCode,
                    name: data.name,
                    token: token,
                    role: data.role,
                    referred: data.referred === '1',
                },
            });
            return token;
        });
    };
    var logout = function () {
        dispatch({
            type: 'LOGOUT',
        });
        return Promise.resolve();
    };
    var setToken = function (token) {
        var data = decodeJWT(token);
        dispatch({
            type: 'LOGIN',
            payload: {
                memberId: data.memberId.toString(),
                registrationCode: data.registrationCode,
                name: data.name,
                token: token,
                role: data.role,
                referred: data.referred === '1',
            },
        });
        return token;
    };
    useEffect(function () {
        Cookies.set('state', btoa(JSON.stringify(state)));
    }, [state]);
    // Hook to loading global state before components rendering (ONLY DEVELOPMENT NEEDS).
    useEffect(function () { return function () { return setIsLoaded(false); }; }, []);
    return (React.createElement(AuthContext.Provider, { value: { state: state, dispatch: dispatch, justVerified: justVerified, login: login, logout: logout, setToken: setToken } }, isLoaded && children));
};
