var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LoadingTypes } from './actions';
export var loadingInitialState = {};
export var loadingReducer = function (state, _a) {
    var _b;
    var type = _a.type, payload = _a.payload;
    if (!(type in LoadingTypes)) {
        return state;
    }
    return __assign(__assign({}, state), (_b = {}, _b[payload.path] = type === LoadingTypes.REQUEST, _b));
};
export * from './actions';
