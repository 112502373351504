var trackPurchase = function (packageId) {
    var transactionId = "free-" + new Date().getTime() + "-" + Math.floor(Math.random() * 1000);
    window['dataLayer'] &&
        window['dataLayer'].push({
            ecommerce: {
                purchase: {
                    actionField: {
                        id: transactionId,
                        revenue: '0.00',
                    },
                    products: [
                        {
                            name: packageId,
                            id: packageId,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
};
export { trackPurchase };
