var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseGateway } from './BaseGateway';
/** @deprecated */
var AuthGateway = /** @class */ (function (_super) {
    __extends(AuthGateway, _super);
    function AuthGateway() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /** @deprecated */
    AuthGateway.prototype.getToken = function (credentials) {
        return this.connection.post('/tokens', credentials);
    };
    /** @deprecated */
    AuthGateway.prototype.sendResetPassword = function (data) {
        return this.connection.post('/passwords', data);
    };
    /** @deprecated */
    AuthGateway.prototype.confirmResetPassword = function (data) {
        return this.connection.put(data.resetUrl, {
            password: data.password,
        }, { withCredentials: true });
    };
    /** @deprecated */
    AuthGateway.prototype.verifyEmail = function (data) {
        return this.connection.post('/verifications', {
            method: 'email',
            identifier: data.email,
        });
    };
    return AuthGateway;
}(BaseGateway));
export { AuthGateway };
