import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { apiConnection } from '@domain';
import { loadingInitialState, loadingReducer, LoadingTypes } from '@store';
import { AuthContext } from '../services/authentication';
var getRequestPath = function (config) {
    if (!config.url || !config.baseURL) {
        return '';
    }
    return config.url.replace(config.baseURL, '');
};
export var RequestContext = createContext({});
export var RequestContextProvider = function (_a) {
    var children = _a.children;
    var authDispatch = useContext(AuthContext).dispatch;
    var _b = useState(false), isLoaded = _b[0], setIsLoaded = _b[1];
    var _c = useReducer(loadingReducer, loadingInitialState), state = _c[0], dispatch = _c[1];
    var handleRequest = function (config) {
        if (!config.url || !config.baseURL) {
            return config;
        }
        dispatch({
            type: LoadingTypes.REQUEST,
            payload: {
                path: getRequestPath(config),
            },
        });
        return config;
    };
    var handleResponse = function (response) {
        var config = response.config;
        if (!config || !config.url || !config.baseURL) {
            return response;
        }
        dispatch({
            type: LoadingTypes.SUCCESS,
            payload: {
                path: getRequestPath(config),
            },
        });
        return response;
    };
    var handleError = function (error) {
        var config = error.config;
        if (!config || !config.url || !config.baseURL) {
            return error;
        }
        var message = _.get(error, 'response.data.message', error.message);
        if (message.indexOf('This token is not signed') >= 0) {
            authDispatch({
                type: 'LOGOUT',
            });
        }
        dispatch({
            type: LoadingTypes.FAILURE,
            payload: {
                path: getRequestPath(config),
                errors: message,
            },
        });
        return Promise.reject(error);
    };
    useEffect(function () {
        var requestInt = apiConnection.interceptors.request.use(handleRequest, handleError);
        var responseInt = apiConnection.interceptors.response.use(handleResponse, handleError);
        setIsLoaded(true);
        return function () {
            setIsLoaded(false);
            apiConnection.interceptors.request.eject(requestInt);
            apiConnection.interceptors.response.eject(responseInt);
        };
    }, []);
    return React.createElement(RequestContext.Provider, { value: { state: state, dispatch: dispatch } }, isLoaded && children);
};
