import React from 'react';
import { hot } from 'react-hot-loader';
// Import general styles first of all to avoid overrides.
import '@assets/scss/App.scss';
// Import local modules.
import { AppRouter } from '@AppRouter';
import { RequestContextProvider } from '@context';
import { AuthContextProvider } from './services/authentication';
import { CookieContextProvider } from './services/cookies';
export default hot(module)(function () { return (React.createElement(CookieContextProvider, null,
    React.createElement(AuthContextProvider, null,
        React.createElement(RequestContextProvider, null,
            React.createElement(AppRouter, null))))); });
